.contact-wrapper {
  background: $color-primary;
  background-image: url('/assets/img/home-contact.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.contact_content {
  background-color: transparent;
}
.container.contact-us {
  max-width: 810px;
  padding: 20px;

  .contact-info {
    font-size: 18px;
    a {
      color: $color-orange;
      text-decoration: underline;
    }
  }

  span.required {
    font-weight: bold;
    font-size: 18px;
    color: $color-red;
  }

  input:focus ~ label,
  input[type=number]:placeholder-shown:focus ~ label,
  input[type=number]:not(:focus):invalid:not(:placeholder-shown) ~ label,
  input[type=email]:not(:focus):invalid:not(:placeholder-shown) ~label,
  input[name=number]:not(:focus):invalid:not(:placeholder-shown) ~label,
  textarea:focus ~ label,
  input:valid ~ label,
  textarea:valid ~ label {
    top: -15px;
    color: #000;
    font-size: 0.75em;
  	font-weight: bold;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
  }

  input[type=number]:not(:focus):invalid:not(:placeholder-shown),
  input[type=email]:not(:focus):invalid:not(:placeholder-shown),
  input[name=number]:not(:focus):invalid:not(:placeholder-shown) {
    outline: 2px solid $color-red;
  }

  .styled-input {
  	margin: 0.5rem 0px;
  	position: relative;
  	border-radius: 2px;
  }

  .styled-input label,
  input[type=number]:placeholder-shown ~ label {
    color: $text-color;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.7rem 20px 0.7rem 20px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
  }

  .styled-input.wide {
  	width: 810px;
    max-width: 100%;
  }

  input,
  textarea {
    padding: 20px;
    width: 100%;
  	outline: 2px solid $color-primary;
    border: 0px;
    border-radius: 4px;
    font-size: 1rem;
    color: $text-color;
  }

  input[type=number],
  input[name=number],
  input[type=email] {
    &::placeholder {
      color: transparent !important;
    }
  }

  input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input:focus,
  textarea:focus { outline-color: $color-secondary; }

  input:focus ~ span,
  textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
  }

  textarea {
    width: 100%;
    min-height: 15em;
    resize: none;
    padding: 0px;
    border: 30px solid transparent;
  }

  .submit-btn {
    width: 225px;
    background-color: $color-secondary;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    transition: all 300ms ease;
    padding: 10px;
    cursor: pointer;
  }

  .submit-btn:hover {
  	background-color: $color-secondary-hover;
  }
}

.g-recaptcha {
  margin-left: -30px;
}
.g-recaptcha iframe {
  max-width: 100% !important;
  transform:scale(0.77) !important;
  -webkit-transform:scale(0.77) !important;
  transform-origin: center center !important;
  -webkit-transform-origin: center center !important;
  margin-left:5px;
}
#rc-imageselect {
  transform:scale(0.77) !important;
  -webkit-transform:scale(0.77) !important;
  transform-origin:0 0 !important;
  -webkit-transform-origin:0 0 !important;
}

@media (max-width: 768px) {
    .container.contact-us {
        .styled-input { width:100%; }
        .submit-btn {
            width:100%;
            text-align:center;
        }
    }
}
