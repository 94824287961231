.members-wrapper {
    .member-container {
        position: relative;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
        border-top: 2px solid $color-secondary;
        border-bottom: 2px solid $color-primary;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 2px;
            height: 50%;
        }
        &:before {
            top: 0;
            left: 0;
            background: $color-primary;
        }

        &:after {
            bottom: 0;
            right: 0;
            background: $color-secondary;
        }
        .heading {
            display: grid;
            align-items: center;
            padding: 20px;
            .h2 {
            font-size: 35px;}
            .h4 {
                font-size: 24px;
            }
            @media (min-width: 768px) {
                grid-template-columns: 1fr 3fr;
                .h2 {font-size: 45px;}
                .h4 {font-size: 36px;}
            }
        }
        .thumbnail {
            width: 200px;
            overflow: hidden;
            border-radius: 50%;
            margin: 20px auto;
        }
    }

    .info-wrapper {
        padding: 11px 20px;
        p {
            text-align: justify;
            text-justify: inter-word;
        }
    }
}
