.hero-wrapper {
	margin-top: -30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 105px);
	background-color: $color-primary;
	background-image: url("/assets/img/homepage-hero-image.webp");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	h1 {
		font-weight: normal;
		line-height: 1.2;
	}

	@media screen and (max-width: 489px) {
		background-image: url("/assets/img/homepage-hero-image-sm.webp");
		min-height: calc(100vh - 168px);
	}
}
