.banner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.banner__logo {
    width: 38.2%;
    @media (min-width: 768px) {
        width: 19.1%;
    }
}

.banner__logo__image {
    width: 100%;
    height: auto;
}

.banner__navigation {
    width: 100%;
    @media (min-width: 992px) {
        width: auto;
    }
}

.banner__navigation--hidden {
    @media (max-width: 991px) {
        display: none;
    }
}

.banner__toggle {
    @media (min-width: 992px) {
        display: none;
    }
}

.banner__navigation__items {
    display: flex;
    @media (max-width: 991px) {
        background: #1e1e21;
        flex-direction: column;
        text-align: center;
        position: absolute;
        left: 0;
        width: 100%;
        transform: translateY(20px);
    }
    @media (min-width: 992px) {
        flex-direction: row;
        text-align: right;
    }
}

.banner__navigation__items a {
    font-size: 16px;
    font-weight: bold;
    color: #f4aa2a;
    padding: 12px 0;
    margin: 0 18px;
    text-decoration: none;
    text-transform: uppercase;
    &.active {
        font-weight: bold;
    }
    &:last-child {
        margin-right: 0;
    }
}
