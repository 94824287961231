main.homepage {
    .vp_content {
        background-color: #eee;
        padding-left: 20px;
        padding-right: 20px;
        .img-thumbnail {
            border-radius: 50%;
        }
        @include media-query($on-palm) {
            h2 { text-align: center; }
        }

        .vp-copy p {
            text-align: justify;
            text-justify: inter-word;
        }
    }
}