a.social-link {
    text-decoration: none;
    &:hover { text-decoration: none !important; }
}

.infobar {
    background-color: $color-primary;
    color: #fff;
    font-size: 18px;

    .social-wrapper {
        cursor: default;
    }

    @include media-query($on-palm) {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 50;
    }

    @media screen and (max-width: 490px) {
        .wrapper-phone .separator {
            display: none;
        }        
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        .info_1 {
            margin-left: auto;
            margin-right: auto;
            padding: 5px;
            text-align: center;
            @media (min-width: 1024px){
                margin-left: 0;
            }
            .d_none {
                display: none;
                @media (min-width: 768px) {
                    display: inline-block;
                }
            }
        }
        .social-wrapper {
            margin-left: auto;
            //margin-right: auto;
            padding: 5px;
            display: none;
            @media (min-width: 1024px) {
                display: block;
            }
        }
    }
}