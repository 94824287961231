@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$color-primary: #416ab3;
$color-secondary: #f72020;
$color-success: #f72020;
$color-grey: #757070;
$color-red: #f72020;
$color-red-contrast:#B30000;
$color-orange: #f4aa20;
$color-orange-contrast: #7A3E00;

$color-secondary-hover: #f49f20;
$color-orange-contrast-hover: lighten($color-orange-contrast, 12%);
$color-red-hover: #ff4a4a;
$color-red-contrast-hover: lighten($color-red-contrast, 10%);

$text-color:       #5e6c7f !default;
$background-color: #fdfdfd !default;
$brand-color:      #2873d5 !default;

$grey-color:       #747474 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1240px !default;

$on-laptop:        992px !default;
$on-tablet:        768px !default;
$on-palm:          600px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout"
;
