body * {
  box-sizing: border-box;
}
.text-white {
  color: #fff !important;
}
.pb-3 {
  padding-bottom: 30px;
}
.pt-3 {
  padding-top: 30px;
}
.pb-5 {
  padding-bottom: 50px;
}
.pt-5 {
  padding-top: 50px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-3 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-4 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.fw-bold {
  font-weight: 700;
}
.page_title {
  font-size: 50px;
  @media (min-width: 768px) {
    font-size: 60px;
  }
  @media (min-width: 1440px) {
    font-size: 90px;
  }
}
.text-center {
  text-align: center;
}
.d-block {
  display: block;
}
.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.h1 {
  font-size: 35px;
  @media (min-width: 768px) {
    font-size: 45px;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
  }
}
.post_content {
  margin-top: 70px;
}
.post-title {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
}

// Clients section
.clients {
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: #e3e8ee;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// .clients-grid
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.clients-grid {
  margin: 100px auto;
  display: grid;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 920px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.clients-grid__item {
  // Each item spans 2 columns, this allows us to center items where needed
  grid-column: span 2;
  @media (min-width: 768px) and (max-width: 919px) {
    // 🗣️ We are expecting 2 items per row so there are 2 scenarios:
    // 0️⃣ orphans, e.g.
    // XX
    // XX
    // We take no action here, things work out fine.
    // 1️⃣ orphan, e.g.
    // XX
    // XX
    // X
    &:last-child:nth-child(2n - 1) {
      grid-column-end: 4;
    }
  }
  @media (min-width: 920px) and (max-width: 1023px) {
    // 🗣️ We are expecting 3 items per row so there are 3 scenarios:
    // 0️⃣ orphans, e.g.
    // XXX
    // XXX
    // We take no action here, things work out fine.
    // 1️⃣ orphan, e.g.
    // XXX
    // XXX
    // X
    &:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
    // 2️⃣ orphans, e.g.
    // XXX
    // XXX
    // XX
    &:nth-last-child(2):nth-child(3n - 2) {
      grid-column-end: 4;
    }
  }
  @media (min-width: 1024px) {
    // 🗣️ We are expecting 4 items per row so there are 4 scenarios:
    // 0️⃣ orphans, e.g.
    // XXXX
    // XXXX
    // We take no action here, things work out fine.
    // 1️⃣ orphan, e.g.
    // XXXX
    // XXXX
    // X
    &:last-child:nth-child(4n - 3) {
      grid-column-end: 6;
    }
    // 2️⃣ orphans, e.g.
    // XXXX
    // XXXX
    // XX
    &:nth-last-child(2):nth-child(4n - 3) {
      grid-column-end: 5;
    }
    // 3️⃣ orphans, e.g.
    // TODO
    // XXXX
    // XXXX
    // XXX
    &:nth-last-child(3):nth-child(4n - 3) {
      grid-column-end: 4;
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.about_grid {
  display: grid;
  gap: 30px;
  img {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    grid-template-columns: 5fr 7fr;
  }
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}
.container-post {
  margin-bottom: 40px;
}
.article-card_item {
  display: grid;
  gap: 0 30px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 5fr;
  }
}
.rounded-circle {
  border-radius: 50%;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.d-flex {
  display: flex;
}
.container-media {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border: 1px solid #e3e8ee;
  border-radius: 6px;
}
.media_grid {
  display: grid;
  gap: 20px;
}
.media_item {
  padding: 20px;
  display: grid;
  gap: 30px;
  text-align: center;
  justify-content: center;
  align-content: center;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 3fr;
    text-align: left;
  }
  .media_text {
    display: grid;
    align-items: center;
    a {
      align-self: center;
      text-decoration: none;
      color: #345;
      .card-title {
        font-size: 24px;
      }
    }
  }
}

.site-logo {
  aspect-ratio: 128 / 43;
}

a.secondary--background-color-override {
  background-color: $color-red-contrast;
  &:hover {
    background-color: $color-red-contrast-hover;
  }
}

a.link-secondary {
  color: $color-orange-contrast;
  &:hover {
    color: $color-orange-contrast-hover;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Utility classes

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.fs-075 {
  font-size: 0.75rem !important;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

hr.custom {
  border: 0;
  height: 5px; /* Thickness */
  width: 50%; /* Width */
  background-color: #f1a722; /* Color */
  margin: 60px auto; /* Center horizontally and add some space around */

  /* Responsive adjustments */
  @media (max-width: 600px) {
      width: 80%; /* Adjust width for smaller screens */
      margin: 40px auto; /* Adjust margin for smaller screens */
  }
}

.image-container {
  text-align: center; /* Center the whole container */
}

.image-container .post-img {
  margin-bottom: 0 !important;
}

.image-container figcaption {
  margin-top: 0.5rem !important; /* Adjust space between image and caption */
  color: #747474; /* Caption text color */
  text-align: center; /* Center-align text */
}

.image-container picture {
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Apply shadow to the picture element */
  margin-bottom: 0.5rem; /* Adjust space below the image */
}

.textarea-description {
  p {
    margin-bottom: 0;
  }
}
