.hamburger {
    position: relative;
    width: 24px;
    height: 20px;
}

.hamburger span {
    position: absolute;
    background-color: #f4aa2a;
    width: 100%;
    height: 3.4px;
    left: 0;
    top: 8px;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
}

.hamburger:before,
.hamburger:after {
    content: '';
    position: absolute;
    background-color: #f4aa2a;
    width: 100%;
    height: 3px;
    left: 0;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
}
.hamburger:before {
    height: 3.4px;
    top: 0;
    border-radius: 10px;
}
.hamburger:after {
    bottom: 0;
}
.hamburger.banner__toggle__item--active:before {
    transform: rotate(45deg);
    top: 9px;
}
.hamburger.banner__toggle__item--active:after {
    transform: rotate(-45deg);
    bottom: 9px;
}
.hamburger.banner__toggle__item--active span {
    transform: scale(0);
}
