.section-services {
    margin-bottom: 50px;
    @media (min-width: 768px) {
        margin-bottom: 100px;
    }
    .service-outer { 
        position: relative; 
        max-width: 424px;
        width: 100%;
        margin-top: 16px;
        display: grid;
        grid-template-rows: 250px auto;
        justify-content: center;
        @media (min-width: 768px) {
            width: 50%;
        }
        @media (min-width: 1440px) {
            width: 33%;
        }
    }
    .bg-service {
        position: absolute;
        z-index: -1;
        border-radius: 6px;
        clip-path: inset(10% 10% 10% 10%);
        top: 70px;
        &--primary {
            background: $color-primary;
        }

        &--secondary {
            background: $color-orange;
        }

        @include media-query($on-tablet) {
            border-radius: 0px;
            &--secondary {
                background: none;
                clip-path: none;
            }
        }
    }

    @include media-query($on-tablet) {
        .service-outer:first-of-type {
            .bg-service--primary {
                clip-path: polygon(0 0, 100% 30%, 100% 70%, 0% 100%);
            }
        }
        .service-outer:nth-of-type(3) {
            .bg-service--primary {
                    background-color: $color-secondary;
                	clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 70%);
            }
        }
    }
    
    img {
        background: #fdfdfd;
        border-radius: 50%;
        border: 1px solid #aaa;
    }

    .service-wrapper {
        background: #fff;
        border-radius: 4px;
        border: 2px solid $text-color;
        padding: 16px;
        h3, h2 { 
          margin-bottom: 20px;
        }

        @include media-query($on-laptop) {
            h3, h2 { min-height: 50px; }
        }
    }

}

.services_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}
.section-services .bg-service--primary {
    background: #416ab3;
}
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.w-100 {
    width: 100% !important;
} 
.h-100 {
    height: 100% !important;
}
img.my-3 {
    margin: 16px auto;
}