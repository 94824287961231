html {
  scroll-behavior: smooth;
}

/**
 * Page content
 */
.page-content {
  flex: 1;
}



/**
 * Thank you page
 */
 .ty-wrapper {
   height: 60vh;
 }



/**
 * Posts
 */
.post-wrapper {
  max-width: 650px;
  font-size: 20px;
  color: $grey-color-dark;
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content--header {
  font-weight: 500;
  color: #757070;

  .row--top {
    border-top: 2px solid $color-primary;
    border-left: 2px solid $color-primary;
    border-right: 2px solid $color-secondary;
  }

  .row--bottom {
    border-right: 2px solid $color-secondary;
    border-left: 2px solid $color-primary;
    border-bottom: 2px solid $color-secondary;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    margin: 3rem 0 1rem;
    padding-left: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(42, 122, 226, 0.5);
    border-left: 1px solid rgba(244,170,32,0.5);
    @include relative-font-size(2);

    @media (min-width: 992px) {
      border-left: none;
      padding-top: 150px;
      margin-top: -115px;
    }

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }

  .post-img {
    box-shadow: 5px 5px 10px #939196;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      z-index: 10;
    }
    &.video-embed {
      background: #000;
      overflow: hidden;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      &:hover {
        -webkit-filter: brightness(75%);
                filter: brightness(75%);
      }
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        display: block;
        width: 110px;
        height: 110px;
        background-image: url('/assets/img/icons/media-control.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.author-wrapper {
  .bg-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: polygon(100% 0, 100% 25%, 75% 100%, 0 100%, 0 0);
    background-color: $color-primary;
    z-index: -1;
  }

  .author-description {
    -webkit-box-shadow: 0px 0px 5px 0px #363537;
            box-shadow: 0px 0px 5px 0px #363537;
  }
}
