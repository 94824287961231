.blockquote {
    border-left: 5px solid #FBAE20;
    padding: 20px;
    margin: 30px auto;
    background-color: #f9f9f9;
    border-radius: 4px;
    max-width: 800px;
    position: relative;
    font-style: italic;
}

.blockquote-body {
    font-size: 1.1em;
    margin-bottom: 10px;
    color: #333;
    line-height: 1.6;
}

.blockquote-footer {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.blockquote-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.blockquote-info {
    display: flex;
    align-items: center;
    color: #555;
}

.blockquote-details {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.blockquote-name-source {
    display: flex;
    align-items: center;
    font-style: normal;
    color: #777;
}

.blockquote-source {
    margin-left: 5px;
}

.blockquote a {
    color: #416AB3;
    text-decoration: none;
    transition: color 0.3s;
}

.blockquote a:hover {
    color: #FBAE20;
    text-decoration: underline;
}