.header-dark {
    background-color: #416ab3;
    color: #f4aa2a;
}
.sticky-top {
    position: sticky;
    top: 0;
}
.site_header {
    top: 0;
    width: 100%;
    min-height: 86px;
    padding: 20px;
    position: sticky;
    z-index: 1200;
    .trigger {
        right: 0;
    }
}
