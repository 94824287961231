.page_footer {
  padding: 30px 20px;
  background: #345;
  .site-logo a {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-content: center;
    img {
      width: 90px;
      height: auto;
    }
    h3 {
      margin: 0;
      height: fit-content;
    }
  }
  .logos * {
    display: block;
    margin: 0 auto;
  }
}
.footer_grid {
  display: grid;
  gap: 30px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 2fr 2fr 1fr 2fr;
  }
}
.footer_c {
  padding: 40px 20px 0;
  margin-top: 30px;
  border-top: 1px solid  #567;
  text-align: center;
}
.footer_info {
  max-width: 390px;
}
.footer_nav {
  display: grid;
}
.social {
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
}