// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$toc-magic-spacing: 1em;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

#markdown-toc::before {
    content: 'Contents';
    position: relative;
    left: -1 * $toc-magic-spacing;
    font-weight: bold;
}

#markdown-toc {
    list-style: disc;
    font-size: smaller;
    margin: 3 * $toc-magic-spacing 0 0 0;
    padding: 0 $toc-magic-spacing;
    // background: orange;
    line-height: 1.6;
    @media (min-width: 992px) {
        // background: gold;
    }
    @media (min-width: 1200px) {
        // background: hotpink;
        position: sticky;
        float: left;
        top: 7 * $toc-magic-spacing;
        width: 14 * $toc-magic-spacing;
        transform: translateX(-100%);
        margin-top: 0;
        margin-right: -100%;
    }
    @media (min-width: 1315px) {
        // background: cyan;
    }
}

#markdown-toc li {
    @media (min-width: 1200px) {
        padding: 0 0.3333em;
        &.markdown-toc__item--active {
            background-color: #f4aa2a;
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
