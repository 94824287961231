/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.text-default {
  color: $text-color !important;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  margin-bottom: 1.5rem;
  font-size: $base-font-size;
  text-align: center;
  color: $grey-color;
}

/**
 * Lists
 */
ul,
ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $base-font-weight;
}

/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }

  &.link {
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
  }

  &.link-secondary {
    color: $color-orange;
    font-size: 17px;
    font-weight: bold;
    &:hover {
      color: $color-secondary-hover;
    }
  }
}

.contact-info--cta {
  a.btn-cta {
    max-width: 350px;
  }
}

a.btn-cta {
  width:fit-content;
  padding: 16px;
  display: block;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  border: none;
  font-weight: 400;
  letter-spacing: 0;
  background-color: $color-secondary;
  border-color: $color-secondary;
  font-size: 16px;
  line-height: 1.04;
  @media(min-width: 768px){
    font-size: 22px;
  }
  text-align: center;
  transition: background-color 0.3s ease;
  border-radius: 6px;
  &:hover {
    text-decoration: none;
    background-color: $color-secondary-hover;
    border-color: $color-red-hover;
  }
}
.button_container {
  //width: max-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  * {
    width: 100% !important;
  }
}
/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

.max-width-1200 {
  max-width: 1200px;
}

/**
 * Clearfix
 */
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/**
 * Icons
 */

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #{$grey-color};
  padding-right: 5px;
  vertical-align: text-top;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}

/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th,
  td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}
